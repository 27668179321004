import React, { useEffect, useReducer, useState } from 'react'
import {
  Alerts,
  useCurrentUser,
  useFileUploader,
  useRPCContext,
  openFilePicker,
  useCurrentUserAnPCheck,
  useCurrentOrganization,
  IsMultiRegion,
  RegionName,
} from '@therms/react-modules'
import {
  ActionModal,
  Avatar,
  Button,
  Icon,
  Inline,
  Label,
  Loading,
  Stack,
} from '@therms/atalaya'
import { EditableField } from '@src/components/EditableField'
import { ChangePrimaryRegionModal } from '@src/modules/Information/router/screens/components/ChangePrimaryRegionModal'
import { MdUpload } from 'react-icons/md'

function reducer(state, fieldName) {
  return {
    ...state,
    [fieldName]: !state[fieldName],
  }
}

function InformationIndex() {
  const [isRegionModalOpen, setIsRegionModalOpen] = useState(false)
  const { rpcService } = useRPCContext()
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const canEditPersonalInfo = !!useCurrentUserAnPCheck(
    (user) => user.anp.role.admin.permissions.manageUsers,
  )

  const [isResettingPassword, setIsResettingPassword] = useState(false)
  const [isAvatarUploadPending, setIsAvatarUploadPending] = useState(false)
  const [fieldsLoadingByName, toggleFieldsLoadingByName] = useReducer(reducer, {
    avatarUrl: false,
    displayName: false,
    email: false,
  })

  const handleFieldUpdate = (
    newValue: string,
    field: string,
    label: string,
    resetField?: () => void,
  ) => {
    toggleFieldsLoadingByName(field)

    return rpcService.Users.UpdateUser.v1
      .call({
        user: {
          [field]: newValue,
          id: currentUser.id,
        },
      })
      .then(() => {
        Alerts.success(`Your ${label} has been updated.`)
      })
      .catch(() => {
        resetField?.()
        Alerts.error(`There was a problem updating your ${label}.`)
      })
      .finally(() => {
        toggleFieldsLoadingByName(field)
      })
  }

  const handlePasswordReset = () =>
    rpcService.Auth.RequestResetPassword.v1
      .call({ email: currentUser.email })
      .then(() => {
        Alerts.success('Password reset request sent.')
      })
      .catch(() => {
        Alerts.error('There was a problem submitting your request.')
      })

  const { files, replaceAllFiles } = useFileUploader()

  useEffect(() => {
    const newAvatar = files[0]

    if (!isAvatarUploadPending || !newAvatar) return
    if (
      newAvatar.status === 'uploaded' &&
      newAvatar.accessUrl &&
      newAvatar.accessUrl !== currentUser.avatarUrl
    ) {
      handleFieldUpdate(newAvatar.accessUrl, 'avatarUrl', 'Avatar').then(() => {
        setIsAvatarUploadPending(false)
      })
    }
  }, [files, isAvatarUploadPending])

  return (
    <>
      <div className="mx-auto flex w-full max-w-lg flex-col pb-base">
        {currentUser.avatarUrl && (
          <Inline alignY="center">
            <div className="mt-lg">
              <Avatar url={currentUser.avatarUrl} circular />
            </div>

            <Stack className="pl-base">
              <div className="pt-sm text-lg">{currentUser.displayName}</div>
              <div className="text-color-neutral">{currentUser.title}</div>
            </Stack>
          </Inline>
        )}

        <div className="mt-xl font-medium uppercase tracking-wide text-color-subtle">
          User Information
        </div>

        <div className="my-xs border-t border-border" />

        <div className="space-y-base">
          <div>
            <Label className="ml-sm" htmlFor="organization">
              Organization
            </Label>

            <EditableField
              name="organization"
              type="text"
              value={organization.name}
            />
          </div>

          <div>
            <Label className="ml-sm" htmlFor="displayName">
              Name
            </Label>

            <EditableField
              canEdit={canEditPersonalInfo}
              loading={fieldsLoadingByName.displayName}
              onSubmit={(newValue, resetField) =>
                handleFieldUpdate(newValue, 'displayName', 'Name', resetField)
              }
              name="displayName"
              type="text"
              value={currentUser.displayName}
            />
          </div>

          <div>
            <Label className="ml-sm" htmlFor="email">
              Email
            </Label>

            <EditableField
              canEdit={canEditPersonalInfo}
              loading={fieldsLoadingByName.email}
              onSubmit={(newValue, resetField) => {
                handleFieldUpdate(newValue, 'email', 'Email', resetField)
              }}
              name="email"
              type="email"
              value={currentUser.email}
            />
          </div>

          {!!currentUser.title && (
            <div>
              <Label className="ml-sm" htmlFor="title">
                Title
              </Label>

              <EditableField
                name="title"
                type="text"
                value={currentUser.title}
              />
            </div>
          )}

          <div className="flex justify-between">
            {isAvatarUploadPending && (
              <Loading message="Uploading image" overlay size="lg" />
            )}

            <Label className="ml-sm">Change Avatar</Label>
            <Button
              outline
              size="sm"
              onClick={() =>
                openFilePicker(
                  (file) => {
                    replaceAllFiles(file)
                    setIsAvatarUploadPending(true)
                  },
                  {
                    accept: 'image/*',
                    multiple: false,
                  },
                )
              }
            >
              <Icon Icon={MdUpload} /> Upload File
            </Button>
          </div>
        </div>

        <div className="flex flex-col space-y-base">
          <div className="mt-lg font-medium uppercase tracking-wide text-color-subtle">
            Account
            <div className="mt-xs border-t border-border" />
          </div>

          <IsMultiRegion>
            <div className="mx-sm">
              <Label className="mb-xxs" htmlFor="title">
                Primary Region
              </Label>

              <div className="flex flex-wrap justify-between">
                <RegionName regionId={currentUser.primaryRegionId} />

                <Button
                  onClick={() => setIsRegionModalOpen(true)}
                  outline
                  size="xs"
                >
                  <span className="px-xxs">Change Primary Region</span>
                </Button>
              </div>
            </div>
          </IsMultiRegion>

          <Button
            className="mx-sm"
            onClick={() => setIsResettingPassword(true)}
            outline
          >
            Reset Password
          </Button>
        </div>
      </div>

      {isRegionModalOpen && (
        <ChangePrimaryRegionModal
          onCancel={() => setIsRegionModalOpen(false)}
          primaryRegionId={currentUser.primaryRegionId}
          onSave={(newPrimaryRegionId) =>
            handleFieldUpdate(
              newPrimaryRegionId,
              'primaryRegionId',
              'Primary Region',
            ).then(() => {
              setIsRegionModalOpen(false)
            })
          }
        />
      )}

      {isResettingPassword && (
        <ActionModal
          body={
            <div>
              Resetting your password will trigger an email that will be sent to
              you with a new temporary password.
            </div>
          }
          escPressClose
          onAction={handlePasswordReset}
          onCancel={() => setIsResettingPassword(false)}
          title="Confirm Password Reset Request"
          variant="main"
        />
      )}
    </>
  )
}

export { InformationIndex }
