import React, { useCallback, useEffect, useRef, useState } from 'react'
import { validateEmail } from '@therms/react-modules'
import { Icon, Loading } from '@therms/atalaya'
import './index.css'
import { MdEdit } from 'react-icons/md'

interface Props {
  canEdit?: boolean
  error?: string
  loading?: boolean
  name: string
  onSubmit?: (value?: string, resetField?: () => void) => void
  type: 'text' | 'email' | 'number'
  value: string
}

function EditableField({
  canEdit = false,
  error,
  loading,
  name,
  onSubmit,
  type,
  value,
}: Props) {
  const [localValue, setLocalValue] = useState(value || '')
  const [emailValidationError, setEmailValidationError] = useState(false)

  const inputRef = useRef()

  const resetField = useCallback(() => setLocalValue(value), [value])

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  return (
    <div>
      <div className="relative">
        <input
          className="editable-field"
          disabled={loading}
          id={name}
          name={name}
          onBlur={(e) => {
            setEmailValidationError(false)

            if (typeof onSubmit === 'function' && localValue !== value) {
              if (type !== 'email') {
                onSubmit(e.target.value, resetField)
              }

              if (
                type === 'email' &&
                !!e.target.value &&
                validateEmail(e.target.value) === undefined
              ) {
                onSubmit(e.target.value, resetField)
              } else {
                setEmailValidationError(true)
              }
            }
          }}
          onChange={(e) => setLocalValue(e.target.value)}
          readOnly={!canEdit || !onSubmit}
          ref={inputRef}
          type={type}
          value={localValue}
        />

        {loading && (
          <div className="absolute inset-y-0 right-sm flex items-center">
            <Loading size="sm" />
          </div>
        )}

        {canEdit && !loading && (
          <div className="pointer-events-none absolute inset-y-0 right-sm flex items-center">
            <Icon Icon={MdEdit} color="neutral-faded" size="xs" />
          </div>
        )}
      </div>

      {!!error && (
        <div className="ml-sm text-sm text-color-critical">{error}</div>
      )}

      {type === 'email' && !!emailValidationError && (
        <div className="ml-sm text-sm text-color-critical">
          Must be a valid email format. "john@email.com"
        </div>
      )}
    </div>
  )
}

export { EditableField }
