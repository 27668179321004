import React, { StrictMode } from 'react'
import {
  AppWrapper,
  RPCContextProvider,
  RouterContextProvider,
  rpcResponseInterceptorLogger,
} from '@therms/react-modules'
import { AppRouter } from './AppRouter'
import { AtalayaWrapper } from '@therms/atalaya'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { GLOBALS } from '../build-env'
import { APPWRAPPER_BACKGROUNDS } from './constants'
import { RPCClientOptions, TransportType } from '@therms/rpc-client'

export const RPC_HOSTS = {
  local: {
    http: 'http://localhost:4000',
    websocket: 'ws://localhost:4001',
  },
  production: {
    http: 'https://rpc-http.therms.app',
    websocket: 'wss://rpc-ws.therms.app',
  },
  remote: GLOBALS.RPC_HTTP_HOST
    ? {
        http: GLOBALS.RPC_HTTP_HOST,
        websocket: GLOBALS.RPC_WS_HOST,
      }
    : undefined,
  sandbox: {
    http: 'https://rpc.sandbox.therms.app',
    websocket: 'wss://rpc.sandbox.therms.app/websocket',
  },
}

const rpcOptions: RPCClientOptions = {
  hosts: RPC_HOSTS?.remote ?? RPC_HOSTS.sandbox,
  transportOptions: {
    preferredTransport: TransportType.http,
  },
  responseInterceptor: rpcResponseInterceptorLogger,
}

console.log('BUILD_ENV', GLOBALS, 'RPC HOSTS', rpcOptions.hosts)
export const history = createBrowserHistory({ basename: '/profile' })
export function App() {
  return (
    <StrictMode>
      <RPCContextProvider options={rpcOptions}>
        <RouterContextProvider history={history}>
          <AtalayaWrapper>
            <AppWrapper
              background={APPWRAPPER_BACKGROUNDS}
              currentAppId="profile"
              currentAppVersion={GLOBALS.BUILD_DATE}
            >
              <Router history={history}>
                <AppRouter />
              </Router>
            </AppWrapper>
          </AtalayaWrapper>
        </RouterContextProvider>
      </RPCContextProvider>
    </StrictMode>
  )
}
