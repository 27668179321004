import React from 'react'
import {
  AuditLogTable,
  NoResults,
  RPCs,
  RPCDocs,
  useCurrentUser,
  usePaginationState,
  useRPC,
} from '@therms/react-modules'
import { Loading } from '@therms/atalaya'

function AuditLogsIndex() {
  const {
    recordsPerPage,
    setRecordsPerPage,
    setSkippedRecords,
    skippedRecords,
  } = usePaginationState('profileauditlogstable', [])
  const currentUser = useCurrentUser()

  const { data, isCalling } = useRPC<
    RPCDocs.Users.GetUserAuditLogs.v1.Args,
    RPCDocs.Users.GetUserAuditLogs.v1.Data
  >(RPCs.Users.GetUserAuditLogs.v1, {
    pagination: {
      limit: recordsPerPage,
      skip: skippedRecords,
    },
    userId: currentUser.id,
  })

  return (
    <div className="relative px-base">
      <Loading visible={isCalling && !data?.total} overlay />

      {data?.total && !isCalling ? (
        <AuditLogTable
          data={data}
          recordsPerPage={recordsPerPage}
          skippedRecords={skippedRecords}
          setSkippedRecords={setSkippedRecords}
          setRecordsPerPage={setRecordsPerPage}
        />
      ) : (
        <NoResults text="No Audit Logs" />
      )}
    </div>
  )
}

export { AuditLogsIndex }
